import React from "react"

import "./project.css"
import "../../node_modules/react-image-gallery/styles/css/image-gallery.css"
import ProjectPage from "../components/projectpage";

const IndexPage = ({ data }) => {
    return (<div className="background">
        <ProjectPage>
            <h1>Searching and sorting analysis on weather data</h1>
            <h4>April - November 2017</h4>
            <p>
                As a high school student in the International Baccalaureate program, I wrote my extended essay in the area of Computer Science, focusing on searching and sorting algorithms. This was my first real exposure to CS theory, and I learned a lot of the basics like asymptotic bounds, space and time complexity, and how a variety of searching and sorting algorithms worked.
            </p>
        </ProjectPage>
    </div>)
}

export default IndexPage
